import { BrowserRouter, Routes, Route } from "react-router-dom";
import FinalizePage from "../pages/inscription/Finalize";
import InscriptionPage from "../pages/inscription/InscriptionPage";
import SearchPage from "../pages/inscription/Search";
import InvitationPage from "../pages/Invitations";
import LoginPage from "../pages/Login";
import ValidationPage from "../pages/Validations";
import RequireAuth from "./RequireAuth";
import * as ROUTE from "./routesDef";
import { MyGoogleReCaptchaProvider } from "../components/MyGoogleReCaptchaProvider";
import InvitationError from "../pages/InvitationError";

const RouterContainer = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={ROUTE.INSCRIPTION}
                    element={
                        <MyGoogleReCaptchaProvider>
                            <InscriptionPage />
                        </MyGoogleReCaptchaProvider>
                    }
                />
                <Route
                    path={ROUTE.NOWHERE}
                    element={
                        <MyGoogleReCaptchaProvider>
                            <InscriptionPage />
                        </MyGoogleReCaptchaProvider>
                    }
                />
                <Route path={ROUTE.LOGIN} element={<LoginPage />} />
                <Route path={ROUTE.SEARCH} element={<SearchPage />} />
                <Route path={ROUTE.FINALISE} element={<FinalizePage />} />
                <Route path={ROUTE.LOGIN} element={<LoginPage />} />
                <Route path={ROUTE.INVITATION_ERROR} element={<InvitationError />} />
                <Route
                    path={ROUTE.VALIDATIONS}
                    element={
                        <RequireAuth>
                            <ValidationPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path={ROUTE.INVITATIONS}
                    element={
                        <RequireAuth>
                            <InvitationPage />
                        </RequireAuth>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default RouterContainer;
