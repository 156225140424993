import { Member } from "../../../entities/entities";
import TextGroup from "../../../components/FormGroups/TextGroup";
import "../../../assets/style/inscription.scss";
import { useFormikContext } from "formik";

const ContactComponent: React.FC = () => {
    const formikContext = useFormikContext<Member>();

    return (
        <div className="container">
            <div className="section">Contact</div>
            <TextGroup
                id="addresse"
                label="Adresse postale"
                type="text"
                value={formikContext.values.contact.addresse}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Member = { ...formikContext.values, contact: { ...formikContext.values.contact, addresse: event.target.value } };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.contact?.addresse}
                //touched={params.touched.contact?.addresse}
                maxLength={40}
                placeholder="Indique ton adresse postale complète"
                required
            />

            <TextGroup
                id="zipCode"
                label="Code postal"
                type="text"
                value={formikContext.values.contact.zipCode}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Member = { ...formikContext.values, contact: { ...formikContext.values.contact, zipCode: event.target.value } };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.contact?.zipCode}
                //touched={params.touched.contact?.zipCode}
                maxLength={40}
                placeholder="Indique ton code postal"
                required
            />
            <TextGroup
                id="city"
                label="Ville"
                type="text"
                value={formikContext.values.contact.city}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Member = { ...formikContext.values, contact: { ...formikContext.values.contact, city: event.target.value } };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.contact?.city}
                //touched={params.touched.contact?.city}
                maxLength={40}
                placeholder="Indique ta ville"
                required
            />
            <TextGroup
                id="cellPhone"
                label="Téléphone mobile"
                type="text"
                value={formikContext.values.contact.cellPhone}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Member = { ...formikContext.values, contact: { ...formikContext.values.contact, cellPhone: event.target.value } };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.contact?.cellPhone}
                //touched={params.touched.contact?.cellPhone}
                maxLength={40}
                placeholder="Indique ton numéro de portable"
                required
            />
            <TextGroup
                id="email"
                label="Email"
                type="text"
                value={formikContext.values.contact.email}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Member = { ...formikContext.values, contact: { ...formikContext.values.contact, email: event.target.value } };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.contact?.email}
                //touched={params.touched.contact?.email}
                maxLength={40}
                placeholder="Indique ton email"
                required
            />
        </div>
    );
};
export default ContactComponent;
