import * as yup from "yup";

export type Creds = { email: string; password: string };

export const credsSchema = yup
    .object()
    .shape({
        email: yup.string().required("login obligatoire"),
        password: yup.string().required("password obligatoire"),
    })
    .noUnknown(true);
