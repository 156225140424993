import { ClimbLevel, ClimbLevelList, Creneau, CreneauList, Member, TarifType, getTarifMin, getTarifSuggere } from "../../../entities/entities";
import SelectGroup from "../../../components/FormGroups/SelectGroup";
import CheckBoxGroup from "../../../components/FormGroups/CheckBoxGroup";
import TarifGroup from "../../../components/FormGroups/TarifGroup";
import "../../../assets/style/inscription.scss";
import { useFormikContext } from "formik";
import { useEffect } from "react";

type EscaladeComponentProps = {
    availableCreneaux: Creneau[];
};

const EscaladeComponent: React.FC<EscaladeComponentProps> = (props: EscaladeComponentProps = { availableCreneaux: ["Adultes"] }) => {
    const formikContext = useFormikContext<Member>();
    const creneauList = CreneauList.filter((e) => props.availableCreneaux.includes(e.value));

    const setCreneau = async (creneau: Creneau): Promise<void> => {
        const tarifType: TarifType = creneau === "Ecole" ? "Ecole" : "Normal";
        const tarif: number = creneau === "Ecole" ? 165 : 35;
        const m: Member = {
            ...formikContext.values,
            escalade: {
                ...formikContext.values.escalade,
                creneau,
                tarifType,
                tarif,
            },
        };
        formikContext.setValues(m, true);
    };
    useEffect(() => {
        if (creneauList && creneauList.length === 1) {
            setCreneau(creneauList[0].value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.availableCreneaux]);

    return (
        <div className="container">
            <div className="section">Escalade</div>

            <SelectGroup
                options={ClimbLevelList}
                placeholder="Fais ton choix"
                id="Niveau"
                label="Niveau"
                onChange={(newValue: ClimbLevel) => {
                    const m: Member = { ...formikContext.values, escalade: { ...formikContext.values.escalade, climbLevel: newValue } };
                    formikContext.setValues(m, true);
                }}
                value={formikContext.values.escalade.climbLevel}
                errors={formikContext.errors.escalade?.climbLevel}
            />

            <SelectGroup
                options={creneauList}
                placeholder="Fais ton choix"
                id="Creneau"
                label="Créneau"
                onChange={(creneau: Creneau) => {
                    setCreneau(creneau);
                }}
                // disabled={creneauList.length === 1}
                value={formikContext.values.escalade.creneau}
                errors={formikContext.errors.escalade?.creneau}
            />
            <CheckBoxGroup
                id="tarifAccompagnateur"
                label="Je suis accompagnateur au créneau famille"
                value={formikContext.values.escalade.tarifType === "Accompagnateur"}
                hidden={formikContext.values.escalade.creneau !== "Famille"}
                onChange={(newValue: boolean) => {
                    const m: Member = {
                        ...formikContext.values,
                        escalade: { ...formikContext.values.escalade, tarifType: newValue ? "Accompagnateur" : "Normal" },
                    };
                    formikContext.setValues(m, true);
                }}
            />
            <CheckBoxGroup
                id="licenceRemark"
                label="J'ai déjà une licence FSGT par un autre club"
                value={formikContext.values.escalade.licenceRemark === "Pas besoin de licences"}
                hidden={formikContext.values.escalade.creneau !== "Adultes"}
                onChange={(newValue: boolean) => {
                    const m: Member = {
                        ...formikContext.values,
                        escalade: { ...formikContext.values.escalade, licenceRemark: newValue ? "Pas besoin de licences" : "Besoin FSGT" },
                    };
                    formikContext.setValues(m, true);
                }}
            />
            <TarifGroup
                id="tarif"
                label="Je m'engage à régler sur hello asso, la somme de :"
                value={formikContext.values.escalade.tarif}
                tarifMin={getTarifMin(
                    formikContext.values.escalade.tarifType!,
                    formikContext.values.escalade.licenceRemark!,
                    formikContext.values.escalade.creneau!
                )}
                tarifSuggere={getTarifSuggere(
                    formikContext.values.escalade.tarifType!,
                    formikContext.values.escalade.licenceRemark!,
                    formikContext.values.escalade.creneau!
                )}
                tarifFixe={formikContext.values.escalade.creneau === "Ecole"}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Member = { ...formikContext.values, escalade: { ...formikContext.values.escalade, tarif: event.target.value } };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                error={formikContext.errors.escalade?.tarif}
                //touched={params.touched.lastname}
                placeholder="Indique ton le montant que tu souhaites payer"
                required
            />
            {formikContext.values.escalade.creneau === "Ecole"
                ? ""
                : "Le tarif est libre (mais minoré), nous l'assumons, ne sentez pas de contraintes, payez selon vos moyens"}
        </div>
    );
};
export default EscaladeComponent;
