import TextGroup from "../../../components/FormGroups/TextGroup";
import "../../../assets/style/inscription.scss";
import { useFormikContext } from "formik";
import { MemberSearchParams } from "../../../entities/entities";

const SearchComponent: React.FC = () => {
    const formikContext = useFormikContext<MemberSearchParams>();

    return (
        <div className="container">
            <div className="section">Etat civil</div>

            <TextGroup
                id="lastname"
                label="Nom"
                type="text"
                value={formikContext.values.lastname}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: MemberSearchParams = { ...formikContext.values, lastname: event.target.value };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.lastname}
                // touched={formikContext.touched?.lastname}
                maxLength={40}
                placeholder="Indique ton nom"
                required
            />

            <TextGroup
                id="prenom"
                label="Prénom"
                type="text"
                value={formikContext.values.firstname}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: MemberSearchParams = { ...formikContext.values, firstname: event.target.value };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.firstname}
                // touched={formikContext.touched?.firstname}
                maxLength={40}
                placeholder="Indique ton prénom"
                required
            />

            <TextGroup
                id="birthDate"
                label="Date de naissance"
                type="text"
                value={formikContext.values.birthDate}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: MemberSearchParams = { ...formikContext.values, birthDate: event.target.value };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors?.birthDate}
                // touched={formikContext.touched?.birthDate}
                maxLength={10}
                placeholder="Indique ta date de naissance (jj/mm/aaaa)"
                required={true}
            />
        </div>
    );
};
export default SearchComponent;
