import React from "react";
import { Field, FormikHandlers } from "formik";
import "../../assets/style/inscription.scss";

type TextGroupParams<T> = {
    id: string;
    type: "text" | "number" | "email";
    label: string;
    placeholder?: string;
    value?: T;
    onBlur?: any;
    handleChange?: FormikHandlers["handleChange"];
    errors?: string;
    touched?: boolean;
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    pattern?: string;
};

const TextGroup = <T extends {}>(params: TextGroupParams<T>): JSX.Element => {
    return (
        <div className="row">
            <div className={"col-25"}>
                <label className="formlabel" htmlFor={params.id}>
                    {params.label}
                </label>
            </div>
            <div className={"col-75"}>
                <Field
                    className={"col-75" + (params.errors && params.touched ? " invalid" : "")}
                    type={params.type}
                    id={params.id}
                    name={params.id}
                    value={params.value}
                    placeholder={params.placeholder}
                    maxLength={params.maxLength}
                    minLength={params.minLength}
                    required={params.required}
                    pattern={params.pattern}
                    onBlur={params.onBlur}
                    onChange={params.handleChange}
                />
                {params.errors && <span className="error-field">{params.errors as string}</span>}
            </div>
        </div>
    );
};

export default TextGroup;
