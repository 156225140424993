import { RecoilRoot } from "recoil";
import RouterContainer from "./routing/routes";

function App() {
    return (
        <div className="App">
            <RecoilRoot>
                <RouterContainer />
            </RecoilRoot>
        </div>
    );
}

export default App;
