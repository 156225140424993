import React, { useEffect, useState } from "react";
import { getSeason } from "../toolServices/helper";
import WaitSpinner from "../components/WaitSpinner/WaitSpinner";
import { defaultQuotas, getQuotas, Quotas } from "../services/preregister";
import { Form, Formik, useFormik } from "formik";
import { createCodeInputSchema, sendCodeInput } from "../entities/invitation";
import * as gestApi from "../services/preregister";
import InvitationComponent from "./inscription/components/Invitation";

import "../assets/style/inscription.scss";
import "../assets/style/invitations.scss";
import "../assets/style/validations.scss";

const InvitationPage: React.FC = () => {
    const [wait, setWait] = useState<boolean>(false);
    const [quotas, setQuotas] = useState<Quotas>(defaultQuotas);

    const initialValues: sendCodeInput = { email: "", creneau: "Ecole", count: 1 };

    useEffect(() => {
        // refreshMembers();
        getQuotas().then((q) => setQuotas(q));
    }, []);

    /**
     * Fonction pour lancer la connexion
     * @param values
     */
    const onSubmit = async (invit: sendCodeInput) => {
        setWait(true);

        try {
            await gestApi.postInvitation(invit);
            const q = await getQuotas(); // Récupérer les quotas à nouveau après l'invitation
            setQuotas(q);
            setValues(initialValues);
            setWait(false);
        } catch (e) {
            setWait(false);
        }
    };

    const { setValues, isValid } = useFormik<sendCodeInput>({ initialValues, onSubmit });

    return (
        <div>
            <h1>Invitations - saison {getSeason()}</h1>
            <div className="Quotas">
                Quota courant : <br />
                Adultes : {quotas.Adultes}
                <br />
                Famille : {quotas.Famille + " / " + quotas.FamilleMax}
                <br />
                Ecole : {quotas.Ecole + " / " + quotas.EcoleMax}
                <br />
                <WaitSpinner wait={wait} />
            </div>

            <Formik initialValues={initialValues} validationSchema={createCodeInputSchema} onSubmit={onSubmit}>
                <Form>
                    <InvitationComponent />
                    <input type="submit" className={"btn btn-submit "} disabled={!isValid} value="Inviter" />
                </Form>
            </Formik>
        </div>
    );
};

export default InvitationPage;
