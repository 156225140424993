import React from "react";
import { getSeason } from "../../toolServices/helper";
import "../../assets/style/inscription.scss";

const FinalizePage: React.FC = () => {
    //const navigate = useNavigate();

    //  const state = useLocation().state;
    //     if (state !== null && state !== undefined) {
    //         initialValues = state as Member;
    //     }
    return (
        <div>
            <h1>Formulaire d'inscription en ligne du GEST - saison {getSeason()}</h1>
            <div className="container">
                <div className="section">Pré-inscription validée</div>
                <p>MERCI, ta préinscription a bien été enregistrée</p>
                <p>Il reste 3 étapes : </p>
                <p> - Le paiement doit être fait en ligne directement ici, voir plus bas; </p>
                <p> - Le certificat médical doit être remis en main propre à un membre du bureau.</p>
                <p> - Un membre du bureau validera l'inscription.</p>
                <p>A la fin tu recevras un mail de confirmation d'inscription. Il te servira éventuellement de facture ou de justificatif de paiement.</p>
            </div>

            <div className="container">
                <div className="section">Notice de paiement</div>
                <p>MERCI, Il ne te reste plus qu'à payer !</p>
                <p>Lorsque tu payera par carte en ligne, il te faut savoir que : </p>
                <p> - Notre partenaire Hello Asso s'ocupe des paiements en ligne et que c'est entièrement sécurisé.</p>
                <p> - Notre partenaire Hello Asso est une association et que son service est totalement GRATUIT.</p>
                <p>
                    {" "}
                    - Notre partenaire Hello Asso <strong>va t'inciter à faire un don</strong> lors de l'étape 3 du processus, tu es totalement libre de faire
                    un don ou non, le montant de ce don ne sera pas connu du GEST. Si tu ne souhaites pas faire ce don, clique sur "Votre contribution au
                    fonctionnement de HelloAsso MODIFIER"
                </p>
                <p> - En cliquant sur le bouton adhérer tu seras guidé à travers les étapes de paiement.</p>
                <p> - Tu pourras payer plusieurs adhésions d'un coup.</p>
                <p> - Si tu inscrit ton enfant à l'école, il n'y aura ni délai, ni facilité de paiement.</p>
                <p>
                    {" "}
                    - Tu pourra choisir le tarif accompagnateur libre minoré à 10€, ou le tarif libre minoré à 35€. Ensuite tu devra saisir le montant que tu
                    t'es engagé à payer.{" "}
                </p>
                <p> - Si tu le souhaites, tu pourra payer en 3 fois. Le tarif n'est plus libre alors, mais de 120€</p>
                <p>
                    {" "}
                    - Il te faudra re-remplir le nom de tous les adhérents pour lesquels tu vas payer. (Afin que nous puissions associer le paiement à un
                    adhérent)
                </p>
                <p> - Il te faudra re-remplir quelques informations personnelles concernant ton paiement. Ces informations seront réservées à Hello Asso</p>
                <p></p>

                <a className="inscription-link" href={"https://www.helloasso.com/associations/gest/adhesions/inscription-famille-" + getSeason()}>
                    {" "}
                    Aller payer sur le site d'hello asso pour le creneau famille
                </a>
                <a className="inscription-link" href={"https://www.helloasso.com/associations/gest/adhesions/inscription-adultes-" + getSeason()}>
                    {" "}
                    Aller payer sur le site d'hello asso pour le creneau adulte
                </a>
                <a className="inscription-link" href={"https://www.helloasso.com/associations/gest/adhesions/inscription-ecole-" + getSeason()}>
                    {" "}
                    Aller payer sur le site d'hello asso pour le creneau école
                </a>
            </div>

            <a className="inscription-link" href="http://www.gest77.fr/?page_id=13">
                {" "}
                OK, Retourner sur le site du GEST
            </a>
        </div>
    );
};

export default FinalizePage;
