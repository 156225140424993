import { Field, FormikHandlers } from "formik";
import "../../assets/style/inscription.scss";

type TarifGroupParams = {
    id: string;
    label: string;
    placeholder?: string;
    value?: number;
    tarifMin: number;
    tarifSuggere: number;
    tarifFixe: boolean;
    onBlur?: any;
    handleChange?: FormikHandlers["handleChange"];
    error?: string; //FormikErrors<T>;
    required?: boolean;
    pattern?: string;
};

const TarifGroup = (params: TarifGroupParams): JSX.Element => {
    return (
        <div className="row">
            <div className="col-25">
                <label className="formlabel" htmlFor={params.id}>
                    {params.label}
                </label>
            </div>
            <div className="tarifsuggere">
                Le tarif minimum est de <span id="tarifminimum">{params.tarifMin}</span> €
            </div>
            <div className="tarifsuggere">
                Le tarif suggéré est de <span id="tarifsuggere">{params.tarifSuggere}</span> €
            </div>

            <div className={"col-75" + (params.error ? "invalid" : "")}>
                <Field
                    type={"number"}
                    id={params.id}
                    name={params.id}
                    value={params.value}
                    disabled={params.tarifFixe}
                    placeholder={params.placeholder}
                    minimum={params.tarifMin}
                    required={params.required}
                    pattern={params.pattern}
                    onBlur={params.onBlur}
                    onChange={params.handleChange}
                />
            </div>
            {params.error && <span className="error-field">{params.error as string}</span>}
        </div>
    );
};

export default TarifGroup;
