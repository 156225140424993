import { useFormikContext } from "formik";
import "../../../assets/style/inscription.scss";
import CheckBoxGroup from "../../../components/FormGroups/CheckBoxGroup";
import { Member } from "../../../entities/entities";
const AccepterComponent: React.FC = () => {
    const formikContext = useFormikContext<Member>();

    const LABEL = (
        <div>
            J'ai pris connaissance des statuts et du{" "}
            <a target="_blank" rel="noreferrer" href="http://www.gest77.fr/?page_id=41">
                règlement de l'association, et de la salle d'escalade.
            </a>
            <br />
            Je les accepte sans réserves.
            <br /> Je renonce à mon droit à l'image dans le cadre des activités de l'association.
        </div>
    );
    //console.log("ERRORS : " + JSON.stringify(formikContext.errors, null, 2));

    return (
        <div className="container">
            <div className="section">Acceptation des conditions</div>
            <div className="row">
                <div className="col-100">
                    <CheckBoxGroup
                        id="accept1"
                        label={LABEL}
                        value={formikContext.values.meta.accepted}
                        onChange={(newValue: boolean) => {
                            const m: Member = { ...formikContext.values, meta: { ...formikContext.values.meta, accepted: newValue } };
                            formikContext.setValues(m, true);
                        }}
                    />
                    <span className="error-field">{formikContext.errors?.meta?.accepted as string}</span>
                </div>
            </div>

            <div>&nbsp;</div>
            <div>Le GEST s'engage à ne communiquer aucune des informations précédentes à aucun organisme tiers.</div>
        </div>
    );
};
export default AccepterComponent;
