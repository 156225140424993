import * as yup from "yup";
import { Creneau, CreneauNames } from "./entities";

export const createCodeInputSchema = yup
    .object()
    .shape({
        email: yup.string().email("invalid mail").required("email required"),
        creneau: yup.mixed<Creneau>().oneOf(Object.values(CreneauNames)).required("creneau required"),
        count: yup.number().min(1).default(1),
    })
    .noUnknown();

export type sendCodeInput = {
    email: string;
    creneau: Creneau;
    count: number;
};
