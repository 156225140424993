import TextGroup from "../../../components/FormGroups/TextGroup";
import "../../../assets/style/inscription.scss";
import { useFormikContext } from "formik";
import { Creds } from "../../../entities/login";

const LoginComponent: React.FC = () => {
    const formikContext = useFormikContext<Creds>();

    return (
        <div className="container">
            <div className="section">Login</div>

            <TextGroup
                id="email"
                label="Email"
                type="text"
                value={formikContext.values.email}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Creds = { ...formikContext.values, email: event.target.value };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.email}
                maxLength={40}
                placeholder="Indique ton email"
                required
            />

            <TextGroup
                id="password"
                label="Mot de passe"
                type="text"
                value={formikContext.values.password}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Creds = { ...formikContext.values, password: event.target.value };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.password}
                maxLength={40}
                placeholder="Indique ton mot de passe"
                required
            />
        </div>
    );
};
export default LoginComponent;
