import { Indexed, FlatMember } from "../../services/preregister";
import "./MemberDetail.scss";

const MemberDetail = ({ indexedMember }: { indexedMember: Indexed<FlatMember> | undefined }): JSX.Element | null => {
    if (indexedMember === undefined) {
        return null;
    }

    return (
        <div>
            id : {indexedMember.member.id} <br />
            lastname : {indexedMember.member.lastname} <br />
            firstname : {indexedMember.member.firstname} <br />
            birthDate : {indexedMember.member.birthDate} <br />
            inscriptionDate : {indexedMember.member.inscriptionDate} <br />
            certificatDate : {indexedMember.member.certificatDate} <br />
            doctor : {indexedMember.member.doctor} <br />
            scanCertif : {indexedMember.member.scanCertif} <br />
            tarif : {indexedMember.member.tarif} <br />
            photo : {indexedMember.member.photo} <br />
            sex : {indexedMember.member.sex} <br />
            addresse : {indexedMember.member.addresse} <br />
            zipCode : {indexedMember.member.zipCode} <br />
            city : {indexedMember.member.city} <br />
            cellPhone : {indexedMember.member.cellPhone} <br />
            receiveSMS : {indexedMember.member.receiveSMS} <br />
            email : {indexedMember.member.email} <br />
            receiveEmails : {indexedMember.member.receiveEmails} <br />
            climbLevel : {indexedMember.member.climbLevel} <br />
            creneau : {indexedMember.member.creneau} <br />
            tarifType : {indexedMember.member.tarifType} <br />
            dateFirstInscription : {indexedMember.member.dateFirstInscription} <br />
            licenceRemark : {indexedMember.member.licenceRemark} <br />
            ffmeNumber : {indexedMember.member.ffmeNumber} <br />
            fsgtNumber : {indexedMember.member.fsgtNumber} <br />
        </div>
    );
};

export default MemberDetail;
