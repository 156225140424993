import { Civilite, CiviliteList, Member } from "../../../entities/entities";
import TextGroup from "../../../components/FormGroups/TextGroup";
import SelectGroup from "../../../components/FormGroups/SelectGroup";
import PhotoGroup from "../../../components/FormGroups/photoGroup";
import "../../../assets/style/inscription.scss";
import { useFormikContext } from "formik";

const EtatCivilComponent: React.FC = () => {
    const formikContext = useFormikContext<Member>();

    return (
        <div className="container">
            <div className="section">Etat civil</div>

            <SelectGroup
                options={CiviliteList}
                placeholder="Fais ton choix"
                id="civilite"
                label="Civilité"
                onChange={(newValue: Civilite) => {
                    const m: Member = { ...formikContext.values, etatCivil: { ...formikContext.values.etatCivil, civilite: newValue } };
                    formikContext.setValues(m, true);
                }}
                value={formikContext.values.etatCivil.civilite}
                errors={formikContext.errors.etatCivil?.civilite}
            />

            <TextGroup
                id="lastname"
                label="Nom"
                type="text"
                value={formikContext.values.etatCivil.lastname}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Member = { ...formikContext.values, etatCivil: { ...formikContext.values.etatCivil, lastname: event.target.value } };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.etatCivil?.lastname}
                // touched={formikContext.touched.etatCivil?.lastname}
                maxLength={40}
                placeholder="Indique ton nom"
                required
            />

            <TextGroup
                id="prenom"
                label="Prénom"
                type="text"
                value={formikContext.values.etatCivil.firstname}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Member = { ...formikContext.values, etatCivil: { ...formikContext.values.etatCivil, firstname: event.target.value } };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.etatCivil?.firstname}
                // touched={formikContext.touched.etatCivil?.firstname}
                maxLength={40}
                placeholder="Indique ton prénom"
                required
            />

            <TextGroup
                id="birthDate"
                label="Date de naissance"
                type="text"
                value={formikContext.values.etatCivil.birthDate}
                handleChange={(event: React.ChangeEvent<any>) => {
                    const m: Member = { ...formikContext.values, etatCivil: { ...formikContext.values.etatCivil, birthDate: event.target.value } };
                    formikContext.setValues(m, true);
                }}
                onBlur={formikContext.handleBlur}
                errors={formikContext.errors.etatCivil?.birthDate}
                // touched={formikContext.touched.etatCivil?.birthDate}
                maxLength={10}
                placeholder="Indique ta date de naissance (jj/mm/aaaa)"
                required={true}
            />
            <PhotoGroup
                id="photo"
                label="Photo"
                value={formikContext.values.etatCivil.photo}
                handleChange={(file: File) => {
                    const m: Member = {
                        ...formikContext.values,
                        etatCivil: {
                            ...formikContext.values.etatCivil,
                            photoBin: file,
                        },
                    };
                    formikContext.setValues(m, true);
                }}
                errors={formikContext.errors.etatCivil?.photoBin}
                touched={formikContext.touched.etatCivil?.photoBin}
                required={true}
            />
        </div>
    );
};
export default EtatCivilComponent;
