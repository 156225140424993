// import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { AgeLevel, Civilite, ClimbLevel, Creneau, Member, MemberSearchParams, Sex, TarifType } from "../entities/entities";
import { sendCodeInput } from "../entities/invitation";
import { Creds } from "../entities/login";
import { getData, postData } from "../toolServices/request";

export const postLogin = async (creds: Creds): Promise<void> => {
    await postData<void>({ url: `${window._env_.REACT_APP_GEST_API}/auth/login`, isPrivate: true, data: creds });
};
export const postLogout = async (): Promise<void> => {
    await postData<void>({ url: `${window._env_.REACT_APP_GEST_API}/auth/logout`, isPrivate: true });
    Cookies.remove("fr.gest77.api");
};

export const getAuthMe = async (): Promise<void> => {
    await getData<void>({ url: `${window._env_.REACT_APP_GEST_API}/auth/me`, isPrivate: true });
};

export const postMember = async (member: Member): Promise<void> => {
    const { data } = await postData<void>({ url: `${window._env_.REACT_APP_GEST_API}/preregister`, isPrivate: true, data: member });
    return data;
};

export const getMe = async (member: MemberSearchParams): Promise<FindMeOutput> => {
    const url = `${window._env_.REACT_APP_GEST_API}/member/findme?lastname=${member.lastname}&firstname=${member.firstname}&birthDate=${member.birthDate}`;

    const result = await getData<FindMeOutput>({ url, isPrivate: true, data: member });
    console.log("result getMe : " + JSON.stringify(result, null, 2));
    return result.data;
};

export const getPreregister = async (): Promise<PaginatedResponse<Indexed<FlatMember>>> => {
    const url = `${window._env_.REACT_APP_GEST_API}/preregister`;
    const { data } = await getData<PaginatedResponse<Indexed<FlatMember>>>({ url, isPrivate: true });
    return data;
};
export const postConfirm = async (id: string, index: number): Promise<void> => {
    const { data } = await postData<void>({ url: `${window._env_.REACT_APP_GEST_API}/preregister/confirm/${id}?index=${index}`, isPrivate: true });
    return data;
};

export const postUploads = async (lastname: string, firstname: string, photo?: File) => {
    const formData = new FormData();
    if (photo) formData.append("photo", photo);
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);

    const { data } = await postData<UploadFilesOutput>({ url: `${window._env_.REACT_APP_GEST_API}/preregister/uploads`, isPrivate: true, data: formData });
    return data;
};

export type Quotas = { [key in Creneau | `${Creneau}Max`]: number };
export const defaultQuotas: Quotas = { Adultes: 0, Famille: 0, Ecole: 0, AdultesMax: 0, FamilleMax: 0, EcoleMax: 0 };
export type Invitation = {
    invitationCode: string;
    email: string;
    creneau: Creneau;
    count: number;
};
export const getQuotas = async (): Promise<Quotas> => {
    const { data } = await getData<Quotas>({ isPrivate: true, url: `${window._env_.REACT_APP_GEST_API}/member/quota` });
    return data;
};

export const postInvitation = async (invit: sendCodeInput): Promise<void> => {
    const { data } = await postData<void>({ url: `${window._env_.REACT_APP_GEST_API}/preregister/invite`, isPrivate: true, data: invit });
    return data;
};

export const checkInvitation = async (invitationCodeCandidate: string): Promise<Invitation> => {
    const { data } = await getData<Invitation>({ url: `${window._env_.REACT_APP_GEST_API}/preregister/invite/${invitationCodeCandidate}`, isPrivate: false });
    return data;
};
export type UploadFilesOutput = {
    errorCount: number;
    errors: { certif?: string; photo?: string };
    success: { certif?: string; photo?: string };
};

export type SheetFormula = string;

export type FindMeOutput = {
    year: string;
    type: "registered" | "preregistered";
    index: number;
    member: {
        isInscrit: 1;
        dossierComplet: boolean | SheetFormula | 0 | 1;
        isLicenceOK: boolean | SheetFormula | 0 | 1;
        id: string;
        lastname: string;
        firstname: string;
        birthDate: string; // check
        inscriptionDate: string;
        certificatDate: string;
        doctor: string;
        scanCertif: string;
        tarif: number;
        modepaiement: string;
        photo: string;
        civilite: Civilite;
        sex: Sex;
        addresse: string;
        zipCode: string;
        city: string;
        cellPhone: string;
        receiveSMS: 0 | 1;
        whatsapp: 0 | 1;
        email: string;
        phone: string;
        receiveEmails: 0 | 1;
        age: number | SheetFormula;
        ageLevel: AgeLevel | SheetFormula;
        climbLevel: ClimbLevel;
        creneau: Creneau;
        tarifType: TarifType;
        dateFirstInscription: string;
        licenceRemark: string;
        fsgtDemandDate?: string;
        fsgtNumber: string;
        ffmeDemandDate?: string;
        ffmeNumber: string;
    };
};

export type PreregisterOutput = {};

export interface PaginatedResponse<T> {
    pageIndex: number;
    totalPage: number;
    elementsPerPage: number;
    totalElements: number;
    prevPageUrl?: string;
    nextPageUrl?: string;
    elements: Array<T>;
}
export const MemberTypeNames = ["registered", "preregistered", "invited"] as const;
export type MemberType = typeof MemberTypeNames[number];
export type MemberResponseType = "full" | "summary";

export type Indexed<T> = { year: number; index: number; type: MemberType; member: T };
export type IndexedMember = Indexed<FlatMember>;

export type FlatMember = {
    id: string;
    lastname: string;
    firstname: string;
    birthDate: string;
    inscriptionDate: string;
    certificatDate: string;
    doctor: string;
    scanCertif: string;
    tarif: string;
    photo: string;
    sex: Sex;
    addresse: string;
    zipCode: string;
    city: string;
    cellPhone: string;
    receiveSMS: 0 | 1;
    email: string;
    receiveEmails: 0 | 1;
    climbLevel: ClimbLevel;

    creneau: string;
    tarifType: string;
    dateFirstInscription: string;
    licenceRemark: string;
    ffmeNumber: string;
    fsgtNumber: string;
};
