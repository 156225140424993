import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";

export type AxiosCustomProps = {
    customHeaders?: AxiosRequestHeaders;
    isPrivate?: boolean; // calling inner api or not
};

export const getData = async <T>({ isPrivate, ...rest }: AxiosRequestConfig & AxiosCustomProps): Promise<AxiosResponse<T>> => {
    const requestOptions: AxiosRequestConfig = {
        method: "GET",
        withCredentials: isPrivate ?? false,
        ...rest,
    };

    return await axios.request<T>(requestOptions);
};

export const postData = async <T>({ isPrivate, ...rest }: AxiosRequestConfig & AxiosCustomProps): Promise<AxiosResponse<T>> => {
    const requestOptions: AxiosRequestConfig = {
        method: "POST",
        withCredentials: isPrivate ?? false,
        ...rest,
    };

    return await axios.request<T>(requestOptions);
};

export const putData = async <T>({ isPrivate, ...rest }: AxiosRequestConfig & AxiosCustomProps): Promise<AxiosResponse<T>> => {
    const requestOptions: AxiosRequestConfig = {
        method: "PUT",
        withCredentials: isPrivate ?? false,
        ...rest,
    };

    return await axios.request<T>(requestOptions);
};

export const delData = async <T, D>({ isPrivate, ...rest }: AxiosRequestConfig<D> & AxiosCustomProps): Promise<AxiosResponse<T, D>> => {
    const requestOptions: AxiosRequestConfig = {
        method: "DELETE",
        withCredentials: isPrivate ?? false,
        ...rest,
    };

    return await axios.request<T>(requestOptions);
};
