import { FormikHandlers } from "formik";
import "./Photos.scss";
import "../../assets/style/inscription.scss";
import { useEffect, useState } from "react";

type PhotoGroupParams = {
    id: string;
    label: string;
    value?: string;
    handleChange?: any;
    errors?: string | undefined;
    touched?: boolean;
    required?: boolean;
    onBlur?: FormikHandlers["handleBlur"];
};

const PhotoGroup = (params: PhotoGroupParams): JSX.Element => {
    const [url, setUrl] = useState<string | undefined>(params.value);
    const getBackgroundImageStyle = (): { backgroundImage: string | undefined } => {
        return { backgroundImage: "url('" + url + "')" };
    };
    useEffect(() => {
        setUrl(params.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fileDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        // console.log("fileDrop");
        event.preventDefault();
        if (event.dataTransfer.files.length) {
            await handleFiles(event.dataTransfer.files);
        }
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        //event.stopPropagation();
        event.preventDefault();
    };

    const handleFiles = async (files: FileList) => {
        const fileok = !!files[0].type.match("image.*");

        if (fileok) {
            // update the underlying object (a photo)
            params.handleChange(files[0]);
            // save the file into a local temporary url.
            setUrl(URL.createObjectURL(files[0]));
        } else {
            // Can not happen, if input.accept correctly set.
            console.log("file not validated");
        }
    };

    const fileSelectedHandler = (event: any) => {
        handleFiles(event.target.files);
    };

    return (
        <div className="row">
            <label className="formlabel" htmlFor={params.id}>
                {params.label}
            </label>

            <div>
                <div className="drag-and-drop">
                    <label htmlFor={params.id}>
                        <input
                            id={params.id}
                            type="file"
                            style={{ display: "none" }}
                            onChange={fileSelectedHandler}
                            // TODO : add pdf.
                            accept="image/png, image/jpg, image/jpeg, image/webp"
                        />
                        <div
                            className={"drop-container required " + (url === "" || url === undefined ? "" : " filled")}
                            //onClick={handleBtnClick}
                            style={getBackgroundImageStyle()}
                            onDrop={(event: React.DragEvent<HTMLDivElement>) => {
                                fileDrop(event);
                            }}
                            onDragOver={(event: React.DragEvent<HTMLDivElement>) => {
                                onDragOver(event);
                            }}
                        />
                    </label>
                </div>
            </div>
            {params.errors && params.touched && <span className="error-field">{params.errors as string}</span>}
        </div>
    );
};

export default PhotoGroup;
