import { useFormikContext } from "formik";
import "../../../assets/style/inscription.scss";
import CheckBoxGroup from "../../../components/FormGroups/CheckBoxGroup";
import { Member } from "../../../entities/entities";

const MedicalComponent: React.FC = () => {
    const formikContext = useFormikContext<Member>();
    return (
        <div className="container">
            <div className="section">Questionnaire médical</div>
            <br />
            Aujourd'hui le certificat médical n'est plus obligatoire. <br />
            Cependant il convient de remplir le formulaire de santé suivant :{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://www.formulaires.service-public.fr/gf/cerfa_15699.do">
                questionnaire
            </a>
            <br />
            Il est interdit de nous fournir ce formulaire remplit (pour des raisons légales de confidentialité médicale). <br />
            Mais vous ne pouvez vous inscrire que si vous n'avez coché que des non.
            <br />
            <br />
            <CheckBoxGroup
                id="questionnaireSante"
                label="J'ai secrètement remplit le formulaire, je certifie n'avoir coché que des nons"
                value={formikContext.values.meta.questionnaireSante}
                onChange={(newValue: boolean) => {
                    const m: Member = {
                        ...formikContext.values,
                        meta: { ...formikContext.values.meta, questionnaireSante: newValue },
                    };
                    formikContext.setValues(m, true);
                }}
            />
            <span className="error-field">{formikContext.errors?.meta?.questionnaireSante as string}</span>
        </div>
    );
};
export default MedicalComponent;
