import React, { useCallback, useState } from "react";
import { Form, Formik } from "formik";
import { getSeason } from "../../toolServices/helper";
import "../../assets/style/inscription.scss";
import { Member, memberSchema } from "../../entities/entities";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as gestApi from "../../services/preregister";
import { useLocation, useNavigate } from "react-router-dom";
import * as ROUTE from "../../routing/routesDef";
import WaitSpinner from "../../components/WaitSpinner/WaitSpinner";
import AvertissementComponent from "./components/Avertissement";
import InscriptionComponent from "./components/Inscription";

// const Romain: Member = {
//     etatCivil: {
//         civilite: "Mr",
//         firstname: "Romain",
//         lastname: "TAILLANDIER",
//         birthDate: "06/12/1978", //new Date(1978, 11, 6),
//         //photo: "https://docs.google.com/file/d/1tpb8_YNuRMI5GCC3r1wf6gawgdXyUH5w/preview?usp=drivesdk",
//         //photo: "https://docs.google.com/file/d/1tpb8_YNuRMI5GCC3r1wf6gawgdXyUH5w/preview",
//         //photo: "https://drive.google.com/uc?id=1tpb8_YNuRMI5GCC3r1wf6gawgdXyUH5w",
//         photo: "https://drive.google.com/uc?id=14c5BpFnyUlhkV3nDpolLFNd_XCswl-Ao",

//         photoBin: undefined,
//     },
//     contact: {
//         addresse: "56 rue THEODORE HONORE",
//         zipCode: "94130",
//         city: "NOGENT SUR MARNE",
//         cellPhone: "06 26 40 65 36",
//         receiveSMS: false,
//         whatsapp: true,
//         email: "romaintaillandier@gmail.com",
//         receiveEmails: true,
//     },
//     escalade: {
//         climbLevel: "Initiateur",
//         creneau: "Adultes",
//         licenceRemark: "Besoin FSGT",
//         tarifType: "Normal",
//         tarif: 120,
//     },
//     meta: {
//         id: "61470034-78d0-46e8-982f-4b306e53001e",
//         sex: "Masculin",
//         fsgtDemandDate: "09/08/2022",
//         fsgtNumber: "12345",
//         inscriptionDate: "09/08/2022",
//         dateFirstInscription: "09/08/2013",
//         accepted: false,
//         questionnaireSante: false,
//     },
// };

const empty: Member = {
    etatCivil: {
        civilite: undefined,
        firstname: undefined,
        lastname: undefined,
        birthDate: undefined,
        photo: undefined,
        photoBin: undefined,
    },
    contact: {
        addresse: undefined,
        zipCode: undefined,
        city: undefined,
        cellPhone: undefined,
        receiveSMS: undefined,
        whatsapp: undefined,
        email: undefined,
        receiveEmails: undefined,
    },
    escalade: {
        climbLevel: undefined,
        creneau: undefined,
        licenceRemark: "Besoin FSGT",
        tarifType: "Normal",
        tarif: 35,
    },
    meta: {
        id: undefined,
        sex: undefined,
        fsgtDemandDate: undefined,
        fsgtNumber: undefined,
        inscriptionDate: undefined,
        dateFirstInscription: undefined,
        accepted: false,
        questionnaireSante: false,
        mode: "inscription",
    },
};

const InscriptionPage: React.FC = () => {
    let initialValues = empty;
    //let initialValues = Romain;
    const navigate = useNavigate();
    const [wait, setWait] = useState<boolean>(false);

    const state = useLocation().state;
    if (state !== null && state !== undefined) {
        initialValues = state as Member;
    }

    //#region Recaptcha implementation.

    // little different from the doc from https://www.npmjs.com/package/react-google-recaptcha-v3
    const { executeRecaptcha } = useGoogleReCaptcha();

    //#endregion recaptcha

    const onSubmit = useCallback(
        async (member: Member) => {
            try {
                setWait(true);
                // Check if we are in production mode (aka need recapchat token)
                //console.log("In production mode, exec recapchat, ", executeRecaptcha, " node env ", window._env_.NODE_ENV);
                if (window._env_.NODE_ENV === "production") {
                    // ressortir le captcha de l'état.
                    // Peut être undefined eventuellement puisque c'est sa valeur initiale.
                    // Mais normalement il sera set avant d'arriver la.
                    if (executeRecaptcha !== undefined) {
                        member.meta.reCaptchaToken = await executeRecaptcha("signup");
                    } else {
                        console.error("Invalid ReCaptcha");
                    }
                } else {
                    member.meta.reCaptchaToken = "dummy local recaptcha";
                }

                if (member.etatCivil.photoBin) {
                    const filenames = await gestApi.postUploads(member.etatCivil.lastname!, member.etatCivil.firstname!, member.etatCivil.photoBin);
                    if (!member.etatCivil.photo) member.etatCivil.photo = filenames.success["photo"];
                }

                await gestApi.postMember(member);
                setWait(false);
                navigate(ROUTE.FINALISE, { state: member });
            } catch (e) {
                setWait(false);
                console.log(e);
            }
        },
        [executeRecaptcha, navigate]
    );

    return (
        <div>
            <h1>Formulaire d'inscription en ligne du GEST - saison {getSeason()}</h1>

            <AvertissementComponent />
            <Formik initialValues={initialValues} validateOnMount={true} validationSchema={memberSchema} onSubmit={onSubmit} validateOnChange={true}>
                <Form>
                    <InscriptionComponent />

                    <input
                        type="submit"
                        title="Envoyer"
                        className={"btn btn-submit "}
                        disabled={false}
                        value="Je déclare sur l'honneur que toutes les informations précédentes sont exactes"
                    />
                </Form>
            </Formik>

            <WaitSpinner wait={wait} />
        </div>
    );
};

export default InscriptionPage;
