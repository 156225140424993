import React from "react";
import "../../../assets/style/inscription.scss";

const SearchAvertissementComponent: React.FC = () => {
    return (
        <div className="container">
            <div className="section">Avertissements</div>

            <p>La ré-inscription peut se faire par internet.</p>
            <p>Tous les champs suivants sont obligatoires.</p>
            <p>
                Une fois retrouvé le formulaire standard de pré-inscription va se remplir avec les données de l'année dernière. Mettez-les à jour si nécessaire.
            </p>
        </div>
    );
};

export default SearchAvertissementComponent;
