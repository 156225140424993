import React from "react";
import { getNextSeason } from "../toolServices/helper";

import "../assets/style/inscription.scss";
import "../assets/style/invitations.scss";
import "../assets/style/validations.scss";

const InvitationError: React.FC = () => {
    return (
        <div>
            <h1>Erreur d'Invitation - saison {getNextSeason()}</h1>
            <div>Il semble que votre code d'invitation ne soit pas correct.</div>
        </div>
    );
};

export default InvitationError;
