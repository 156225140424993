import "../../assets/style/inscription.scss";

type CheckBoxGroupParams = {
    id: string;
    label: string | JSX.Element;
    value?: boolean;
    hidden?: boolean;
    onChange: (newValue: boolean) => void;
};

const CheckBoxGroup = (params: CheckBoxGroupParams): JSX.Element => {
    //console.log("CheckBoxGroup param.value = " + params.value);

    return params.hidden ? (
        <></>
    ) : (
        <div className="row">
            <div className="row" id="divnolic">
                <div className="col-100">
                    <label className="checkboxcontainer" htmlFor={params.id}>
                        {params.label}
                        <input type="checkbox" id={params.id} checked={params.value} onChange={(e) => params.onChange(!params.value)} />
                        <span className="checkboxcheckmark"></span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default CheckBoxGroup;
