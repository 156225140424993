import React from "react";
import TopBar from "../components/TopBar/TopBar";

const PageLayout = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    return (
        <>
            <TopBar />
            <div>{children}</div>
        </>
    );
};

export default PageLayout;
