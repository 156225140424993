import React from "react";
import * as ROUTE from "../../../routing/routesDef";
import "../../../assets/style/inscription.scss";

const AvertissementComponent: React.FC = () => {
    return (
        <div className="container">
            <div className="section">Avertissements</div>
            <p>
                Pour la réinscription c'est <a href={ROUTE.SEARCH}>ici</a>{" "}
            </p>

            <p>La préinscription peut se faire par internet.</p>
            <p>Tous les champs suivants sont obligatoires.</p>
            <p>Le paiement se fait par paiement par carte bancaire en ligne.</p>
            <p>A la fin de la procédure d'inscription, des instructions sur le paiement te seront communiquées.</p>
            <p>&nbsp;</p>
            <p>
                <strong>Pour le créneau famille :</strong>
            </p>
            <p>Porte une attention particulière à ce qui suit, car chaque année nous rencontrons des difficultés</p>
            <p></p>
            <p>
                - Les enfants doivent s'acquitter du tarif libre (&gt;35€) <br />- <strong>Chacun de ses accompagnateurs doit s'inscrire également</strong>
                <br />- <strong>Enfants ET accompagnateurs doivent s'inscrire</strong>
                <br />
                - Les accompagnateurs doivent se déclarer comme tel (case à cocher en fin de formulaire), le tarif acompagnateur est libre (&gt;10€)
                <br />
                Nous licencions les parents ET les enfants, pour que tout le monde soit couvert en terme d'assurance. C'est pourquoi nous avons vraiment besoin
                que tu respectes ces règles.
            </p>

            <p>
                <strong>Justificatifs :</strong>
            </p>
            <p>
                Durant la procédure tu dois disposer de : <br />
                <br />- une photo portrait de toi de bonne qualité.
            </p>
        </div>
    );
};

export default AvertissementComponent;
