import PageLayout from "../pages/pageLayout";
import WaitSpinner from "../components/WaitSpinner/WaitSpinner";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import * as ROUTE from "../routing/routesDef";

const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const authCookie = Cookies.get("fr.gest77.api");
        if (authCookie) {
            setIsLoggedIn(true);
        } else {
            navigate(ROUTE.LOGIN, {
                state: { from: location },
            });
        }
        setIsLoading(false);
    }, [location, navigate]);

    if (isLoading) {
        return <WaitSpinner wait />;
    }

    if (!isLoggedIn) {
        return <WaitSpinner wait />;
    }

    return <PageLayout>{children}</PageLayout>;
};

export default RequireAuth;
