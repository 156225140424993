import React from "react";
import { useNavigate } from "react-router-dom";
import "./TopBar.scss";

import * as ROUTE from "../../routing/routesDef";
import * as gestApi from "../../services/preregister";
import logo from "../../assets/icons/logo32.png";

const TopBar: React.FC = () => {
    const navigate = useNavigate();

    // const auth = Cookies.get("fr.gest77.api");
    // if (!auth) {
    //     navigate(ROUTE.LOGIN);
    // }

    /**
     * Fonction pour réinitialiser l'audit
     */
    const validation = async () => {
        console.log("click on validation");
        navigate(ROUTE.VALIDATIONS);
    };

    const invitations = async () => {
        console.log("click on invitations");
        navigate(ROUTE.INVITATIONS);
    };
    /**
     * Fonction pour déconnecter l'utilisateur
     */
    const disconnect = async () => {
        // Lancement déconnexion
        gestApi.postLogout();
        navigate(ROUTE.LOGIN);
    };

    return (
        <div className="navbar">
            <div className="container">
                <div className="menu">
                    <img src={logo} alt="" />
                </div>
                <button className="menu btn" onClick={() => invitations()}>
                    Invitations
                </button>
                <button className="menu btn" onClick={() => validation()}>
                    Validations
                </button>

                <button className="menu btn" onClick={() => disconnect()}>
                    Déconnexion
                </button>
            </div>
        </div>
    );
};

export default TopBar;
