import { FormikValues, useFormik } from "formik";

export const getSeason = (): string => {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const year = dateObj.getUTCFullYear();
    const yearNext = year + 1;
    const yearPrev = year - 1;

    if (month >= 6) return year + "-" + yearNext;
    else return yearPrev + "-" + year;
};
export const getSeasonStartYear = (season: string): string => {
    return season.split("-")[0];
};
export const getNextSeason = (): string => {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const year = dateObj.getUTCFullYear() + 1;
    const yearNext = year + 1;
    const yearPrev = year - 1;

    if (month >= 6) return year + "-" + yearNext;
    else return yearPrev + "-" + year;
};
/**
 * Fonction pour retrouver la valeur de défaut d'un champ via une liste d'éléments
 * @param propertyList
 * @param value
 *
 * @remark
 *  * How To Use
 *
 * <Select
 *  defaultValue={renderDefaultValueSelect(optionsList, values.propertyName)}
 *  options={optionsList}
 *  ...
 */

export const renderDefaultValueSelectMulti = <T>(propertyList: Array<Labelled<T>>, value: T, multiSelect: boolean): Array<Labelled<T>> => {
    return propertyList.filter((property: any) => {
        return property.value === value;
    });
};
export const renderSelected = <T>(propertyList: Array<Labelled<T>>, value: T): Labelled<T> => {
    return (
        propertyList.find((property) => {
            return property.value === value;
        }) || propertyList[0]
    );
};

export type Labelled<T> = { label: string; value: T };
export type LabelledString = Labelled<string>;

// export type ReactComponentParameter<PARAM> = {
//     values: PARAM;
//     errors: FormikErrors<PARAM>;
//     touched: FormikTouched<PARAM>;
//     handleChange: {
//         (e: React.ChangeEvent<any>): void;
//         <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
//     };
//     handleBlur: {
//         (e: React.FocusEvent<any, Element>): void;
//         <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
//     };
//     setValues: (values: React.SetStateAction<PARAM>, shouldValidate?: boolean | undefined) => void;
// };

const pickerFormik = ["values", "errors", "touched", "handleChange", "handleBlur", "setValues"] as const;
type FormikReturnType<T extends FormikValues> = ReturnType<typeof useFormik<T>>;

export type ReactComponentParameter<T extends FormikValues> = Pick<FormikReturnType<T>, typeof pickerFormik[number]>;
