import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { getSeason, getSeasonStartYear } from "../../toolServices/helper";
import "../../assets/style/inscription.scss";
import { MemberSearchParams, searchParamSchema, toMember } from "../../entities/entities";
import SearchAvertissementComponent from "./components/Avertissement_find";
import SearchComponent from "./components/Search";
import * as gestApi from "../../services/preregister";
import * as ROUTE from "../../routing/routesDef";
import WaitSpinner from "../../components/WaitSpinner/WaitSpinner";

// const Romain: MemberSearchParams = {
//     firstname: "Romain",
//     lastname: "TAILLANDIER",
//     birthDate: "06/12/1978", //new Date(1978, 11, 6),
// };

const empty: MemberSearchParams = {
    firstname: undefined,
    lastname: undefined,
    birthDate: undefined,
};

const SearchPage: React.FC = () => {
    const navigate = useNavigate();
    const initialValues = empty;
    const [wait, setWait] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [alreadyRegistered, setAlreadyRegistered] = useState<boolean>(false);
    const [alreadyPreRegistered, setAlreadyPreRegistered] = useState<boolean>(false);

    const onSubmit = async (search: MemberSearchParams) => {
        setWait(true);

        try {
            setNotFound(false);
            setAlreadyPreRegistered(false);
            setAlreadyRegistered(false);

            // //values.meta.reCaptchaToken = reCaptcha;
            const Result = await gestApi.getMe(search);
            console.log("Result = " + JSON.stringify(Result, null, 2));

            if (Result.year === getSeasonStartYear(getSeason())) {
                if (Result.type === "registered") {
                    console.log("dejà inscrit");
                    setAlreadyRegistered(true);
                    setWait(false);
                    return;
                }
                if (Result.type === "preregistered") {
                    console.log("dejà préinscrit");
                    setAlreadyPreRegistered(true);
                    setWait(false);
                    return;
                }
            }

            const member = await toMember(Result);
            setWait(false);
            navigate(ROUTE.INSCRIPTION, { state: member });
        } catch (e) {
            setWait(false);
            setNotFound(true);
        }
    };

    return (
        <div>
            <h1>Formulaire d'inscription en ligne du GEST - saison {getSeason()}</h1>

            <SearchAvertissementComponent />
            <Formik initialValues={initialValues} validateOnMount={true} validationSchema={searchParamSchema} onSubmit={onSubmit} validateOnChange={true}>
                <Form>
                    <SearchComponent />
                    {notFound ? (
                        <div style={{ color: "red", fontSize: "18px", textAlign: "center" }}>
                            Je ne t'ai pas trouvé <br />
                        </div>
                    ) : (
                        <></>
                    )}
                    {alreadyRegistered ? (
                        <div style={{ color: "red", fontSize: "18px", textAlign: "center" }}>
                            Tu es déjà inscrit pour cette saison <br />
                        </div>
                    ) : (
                        <></>
                    )}
                    {alreadyPreRegistered ? (
                        <div style={{ color: "red", fontSize: "18px", textAlign: "center" }}>
                            Tu es déjà pré-inscrit pour cette saison, patiente pour ta validation <br />
                        </div>
                    ) : (
                        <></>
                    )}
                    <input
                        type="submit"
                        title="Envoyer"
                        className={"btn btn-submit "} // + (Object.entries(formikMember.errors).length === 0 ? "disabled" : "")}
                        //disabled={Object.entries(formikMember.errors).length !== 0}
                        disabled={false}
                        value="Vérifier si j'existe"
                    />
                </Form>
            </Formik>
            <WaitSpinner wait={wait} />
        </div>
    );
};

export default SearchPage;
