import TextGroup from "../../../components/FormGroups/TextGroup";
import "../../../assets/style/inscription.scss";
import { useFormikContext } from "formik";
import { sendCodeInput } from "../../../entities/invitation";
import { Creneau, CreneauList } from "../../../entities/entities";
import SelectGroup from "../../../components/FormGroups/SelectGroup";

const InvitationComponent: React.FC = () => {
    const formikContext = useFormikContext<sendCodeInput>();

    return (
        <div className="container">
            <div className="section">Invitations</div>
            <TextGroup
                id="email"
                label="Email"
                type="text"
                value={formikContext.values.email}
                handleChange={(event: React.ChangeEvent<any>) => {
                    formikContext.setValues({ ...formikContext.values, email: event.target.value }, true);
                }}
                errors={formikContext.errors.email}
                maxLength={40}
                placeholder="Indique ton email"
                required
            />
            <SelectGroup
                options={CreneauList}
                placeholder="Fais ton choix"
                id="Creneau"
                label="Créneau"
                onChange={(newValue: Creneau) => {
                    formikContext.setValues({ ...formikContext.values, creneau: newValue }, true);
                }}
                value={formikContext.values.creneau}
                errors={formikContext.errors.creneau}
            />
            <TextGroup
                id="count"
                label="Combien de place"
                type="number"
                value={formikContext.values.count}
                handleChange={(event: React.ChangeEvent<any>) => {
                    formikContext.setValues({ ...formikContext.values, count: event.target.value }, true);
                }}
                errors={formikContext.errors.count}
                maxLength={40}
                placeholder="Indique le nombre de place"
                required
            />
        </div>
    );
};
export default InvitationComponent;
