import "./WaitSpinner.scss";

const WaitSpinner = ({ wait }: { wait: boolean }): JSX.Element => {
    return (
        <div className="fader" style={{ display: wait ? "block" : "none" }}>
            <div className="loader">
                <div className="loading-text">Merci de patienter ... </div>
            </div>
        </div>
    );
};

export default WaitSpinner;
