import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import EtatCivilComponent from "./Etacivil";
import MedicalComponent from "./Medical";
import ContactComponent from "./Contact";
import EscaladeComponent from "./Escalade";
import AccepterComponent from "./Accepter";
import "../../../assets/style/inscription.scss";
import { Creneau, Member, TarifType } from "../../../entities/entities";
import * as gestApi from "../../../services/preregister";
import { useNavigate } from "react-router-dom";
import * as ROUTE from "../../../routing/routesDef";

const InscriptionComponent: React.FC = () => {
    //let initialValues = Romain;
    const navigate = useNavigate();

    const formikContext = useFormikContext<Member>();
    //console.log("initial mode : " + formikContext.values.meta.mode);

    const [availableCreneau, setAvailableCreneau] = useState<Creneau[]>(["Adultes", "Ecole", "Famille"]);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const codeEcoleParam = urlSearchParams.get("codeEcole");
        const codeFamilleParam = urlSearchParams.get("codeFamille");
        let code = "";

        if (codeEcoleParam) {
            setAvailableCreneau(["Ecole"]);
            code = codeEcoleParam;
        }
        if (codeFamilleParam) {
            setAvailableCreneau(["Famille"]);
            code = codeFamilleParam;
        }

        if (code)
            gestApi
                .checkInvitation(code)
                .then((invitation) => {
                    if (invitation.count <= 0) {
                        navigate(ROUTE.INVITATION_ERROR);
                        return;
                    }
                    const tarifType: TarifType = invitation.creneau === "Ecole" ? "Ecole" : "Normal";
                    const tarif: number = invitation.creneau === "Ecole" ? 165 : 35;

                    formikContext.setValues({
                        ...formikContext.values,
                        meta: {
                            ...formikContext.values.meta,
                            mode: "invitation",
                            invitationCode: invitation.invitationCode,
                        },
                        contact: {
                            ...formikContext.values.contact,
                            email: invitation.email,
                        },
                        escalade: {
                            ...formikContext.values.escalade,
                            creneau: invitation.creneau,
                            tarifType,
                            tarif,
                        },
                    });
                })
                .catch((ex) => {
                    console.log("inscription failed " + ex);
                    navigate(ROUTE.INVITATION_ERROR);
                });
        else {
            // pas si simple
            //setAvailableCreneau(["Adultes"]);
            switch (formikContext.values.meta.mode) {
                case "reinscription": {
                    console.log("reinscription .creneau " + formikContext.values.escalade.creneau);
                    switch (formikContext.values.escalade.creneau) {
                        case "Famille":
                            // ue réinscription de quelqu'un qui était déjà au créneau famille :(accompagnateur ou enfant)
                            if (formikContext.values.escalade.tarifType === "Accompagnateur") setAvailableCreneau(["Adultes", "Famille"]);
                            else setAvailableCreneau(["Adultes", "Ecole", "Famille"]);
                            break;
                        case "Ecole":
                            setAvailableCreneau(["Adultes", "Ecole"]);
                            break;
                        case "Adultes":
                            setAvailableCreneau(["Adultes"]);
                            break;
                    }
                    break;
                }
                case "inscription": {
                    setAvailableCreneau(["Adultes"]);
                    break;
                }
                case "invitation": {
                    console.log("déjà traité !");
                    break;
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <EtatCivilComponent />
            <MedicalComponent />
            <ContactComponent />
            <EscaladeComponent availableCreneaux={availableCreneau} />
            <AccepterComponent />
        </div>
    );
};

export default InscriptionComponent;
