import React, { useEffect, useState } from "react";
import { getSeason } from "../toolServices/helper";
import WaitSpinner from "../components/WaitSpinner/WaitSpinner";
import { FlatMember, getPreregister, Indexed, postConfirm } from "../services/preregister";
import MemberDetail from "../components/MemberDetail/MemberDetail";

import "../assets/style/inscription.scss";
import "../assets/style/validations.scss";

const ValidationPage: React.FC = () => {
    const [wait, setWait] = useState<boolean>(false);
    const [indexedMembers, setMembers] = useState<Array<Indexed<FlatMember>>>(Array<Indexed<FlatMember>>());
    const [preview, setPreview] = useState<string>("");
    const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(undefined);
    const [previewMode, setPreviewMode] = useState<"photo" | "detail">("photo");

    useEffect(() => {
        refreshMembers();
    }, []);

    const refreshMembers = async (): Promise<void> => {
        setWait(true);
        try {
            const result = await getPreregister();
            setMembers(result.elements);
        } catch (e) {
            alert("une erreur est survenue, l'inscription n'est pas encore validée. Contactez Romain, si ca continue de merder");
        }
        setWait(false);
    };

    const validate = async (memberId: string, index: number) => {
        setWait(true);
        try {
            await postConfirm(memberId, index);
        } catch (e) {
            alert("une erreur est survenue, l'inscription n'est pas encore validée. Contactez Romain, si ca continue de merder");
        }
        setWait(false);
        await refreshMembers();
    };

    const onClickDetail = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        setSelectedMemberId(id);
    };

    return (
        <div>
            <h1>Validation des inscriptions - saison {getSeason()}</h1>

            <h3>
                <a href="https://www.helloasso.com/associations/gest/administration/statistiques">
                    Le site de hello asso pour vérifier les paiement (bas de page){" "}
                </a>
            </h3>
            <h3>
                <a href="https://docs.google.com/spreadsheets/d/1CW2tYVbG1iLQ-TDFrtSwQ2sqfNipIV23hpcdsN3ofac/edit#gid=0">Liste des préinscrits sur le sheet</a>
            </h3>
            <br />

            <table className="potentialmembers">
                <thead>
                    <tr>
                        <th></th>
                        <th>Détails</th>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Date de naissance</th>
                        <th>Date Inscription</th>
                        <th>Email</th>
                        <th>Creneau</th>
                        <th>Tarif</th>
                        <th>Photo</th>
                    </tr>
                </thead>
                <tbody>
                    {indexedMembers.map((indexedMember, index) => (
                        <tr key={index}>
                            <td>
                                <div
                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                    onClick={(e) => validate(indexedMember.member.id, indexedMember.index)}
                                >
                                    Valider
                                </div>
                            </td>
                            <td>
                                <div
                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                    onClick={(e) => {
                                        setPreviewMode("detail");
                                        onClickDetail(e, indexedMember.member.id);
                                    }}
                                >
                                    Détails
                                </div>
                            </td>
                            <td>{indexedMember.member.firstname}</td>
                            <td>{indexedMember.member.lastname}</td>
                            <td>{indexedMember.member.birthDate}</td>
                            <td>{indexedMember.member.inscriptionDate}</td>
                            <td>{indexedMember.member.email}</td>
                            <td>{indexedMember.member.creneau}</td>
                            <td>{indexedMember.member.tarif}</td>
                            <td>
                                <div
                                    onMouseEnter={(e) => {
                                        setPreviewMode("photo");
                                        setPreview(indexedMember.member.photo);
                                    }}
                                    style={{ textDecoration: "underline", cursor: "zoom-in" }}
                                >
                                    Photo
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="idiv" id="idiv">
                {previewMode === "photo" ? (
                    <img title="preview" className="framecontainer" src={preview} alt="membre" />
                ) : (
                    <MemberDetail indexedMember={indexedMembers.find((e) => e.member.id === selectedMemberId)} />
                )}
            </div>
            {/* <Popup display={selectedMemberId !== undefined} closeCallback={() => setSelectedMemberId(undefined)}>
                <MemberDetail indexedMember={indexedMembers.find((e) => e.member.id === selectedMemberId)} />
            </Popup> */}
            <WaitSpinner wait={wait} />
        </div>
    );
};
export default ValidationPage;
