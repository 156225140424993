import * as yup from "yup";
import { FindMeOutput } from "../services/preregister";
import { Labelled } from "../toolServices/helper";

type EtatCivil = {
    civilite?: Civilite;
    firstname?: string;
    lastname?: string;
    birthDate?: string; // todo : string ou date ?   incohérence ave cle yup
    /** photourl */
    photo?: string;
    photoBin?: File;
};

// type Certificat = {
//     certificatDate?: string;
//     doctor?: string;
//     scanCertif?: string;
//     scanCertifBin?: File;
// };

type Contact = {
    addresse?: string;
    zipCode?: string;
    city?: string;
    cellPhone?: string;
    email?: string;
    receiveSMS?: boolean;
    whatsapp?: boolean;
    receiveEmails?: boolean;
};

type Escalade = {
    climbLevel?: ClimbLevel;
    creneau?: Creneau;
    licenceRemark?: LicenceRemark;
    tarifType?: TarifType;
    tarif?: number;
};

export type MemberMetaData = {
    id?: string;
    sex?: Sex;

    fsgtDemandDate?: string;
    fsgtNumber?: string;
    inscriptionDate?: string;
    dateFirstInscription?: string;
    ffmeNumber?: string;
    ffmeDemandDate?: string;
    mode?: InscriptionMode;
    invitationCode?: string;
    scancertifObsolete?: boolean;

    accepted?: boolean;
    questionnaireSante?: boolean;

    reCaptchaToken?: string;
};

export type Member = {
    etatCivil: EtatCivil;
    // certificat: Certificat;
    contact: Contact;
    escalade: Escalade;
    meta: MemberMetaData;
};

export type MemberSearchParams = Pick<EtatCivil, "firstname" | "lastname" | "birthDate">;

export type FlatMember = EtatCivil & Contact & Escalade & MemberMetaData;

export const CreneauLabelNames = [
    "Famille (mercredi 19h00 - 20h30)",
    "Ecole (Mardi 18h30 - 20h00)",
    "Adultes / Ados (lundi 19h30 - 22h00 mercredi 20h30 - 23h00 vendredi 20h30 - 23h00)",
] as const;
export const CreneauNames = ["Famille", "Ecole", "Adultes"] as const;
export type Creneau = typeof CreneauNames[number];

export const TarifTypeNames = ["Réduit", "Bureau", "Ecole", "Accompagnateur", "Normal", "Libre"] as const;
export type TarifType = typeof TarifTypeNames[number];

export const LicenceRemarkNames = ["Pas besoin de licences", "Besoin des deux licences", "Besoin FFME", "Besoin FSGT"] as const;
export type LicenceRemark = typeof LicenceRemarkNames[number];

export const SexNames = ["Masculin", "Féminin"] as const;
export type Sex = typeof SexNames[number];

export const CiviliteNames = ["Mr", "Mme"] as const;
export type Civilite = typeof CiviliteNames[number];

export const ClimbLevelNames = ["Débutant", "Autonome", "Compétition", "Initiateur"] as const;
export type ClimbLevel = typeof ClimbLevelNames[number];

export const AgeLevelName = ["6-", "7-11", "12-14", "15-19", "20-24", "25-39", "40-44", "45-49", "50-54", "55-59", "60+"] as const;
export type AgeLevel = typeof AgeLevelName[number];

export const MemberTypeNames = ["registered", "preregistered"] as const;
export type MemberType = typeof MemberTypeNames[number];
export type MemberResponseType = "full" | "summary";

export const InscriptionModeNames = ["inscription", "reinscription", "invitation"] as const;
export type InscriptionMode = typeof InscriptionModeNames[number];

const buildList = <T>(labels: Array<string>, values: Array<T>): Array<Labelled<T>> => {
    const result = new Array<Labelled<T>>();
    for (const i in labels) result.push({ label: labels[i], value: values[i] });
    return result;
};

// const buildListWithUndefined = <T>(labels: Array<string>, values: Array<T>): Array<Labelled<T>> => {
//     const result = new Array<Labelled<T>>();
//     for (const i in labels) result.push({ label: labels[i], value: values[i] });
//     return result;
// };

export const CiviliteList = buildList(Object.values(CiviliteNames), Object.values(CiviliteNames));
export const ClimbLevelList = buildList(Object.values(ClimbLevelNames), Object.values(ClimbLevelNames));
export const CreneauList = buildList(Object.values(CreneauLabelNames), Object.values(CreneauNames));

export const frenchDateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
export const inputCellPhoneRegex = /^(0[67][0-9]{8}$)|(^0[67]( [0-9][0-9]){4}$)/;
// uuid v4 : a1d2499e-8c37-4b48-b2b2-8c8990ab4fbd
export const memberIdRegex = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;

export const FILE_SIZE = 10000000; // 10 Mo
export const SUPPORTED_FORMATS = ["image/png", "image/jpg", "image/jpeg", "image/webp"];

export const getTarifMin = (tarifType: TarifType | undefined, licenceRemark: LicenceRemark | undefined, creneau: Creneau | undefined): number => {
    if (creneau === undefined || tarifType === undefined || licenceRemark === undefined) return 35;
    if (creneau === "Ecole") return 165;
    if (tarifType === "Accompagnateur" && creneau === "Famille") return 10;
    if (licenceRemark === "Pas besoin de licences") return 0;
    return 35;
};
export const getTarifSuggere = (tarifType: TarifType | undefined, licenceRemark: LicenceRemark | undefined, creneau: Creneau | undefined): number => {
    if (creneau === undefined || tarifType === undefined || licenceRemark === undefined) return 120;
    if (creneau === "Ecole") return 165;
    if (tarifType === "Accompagnateur" && creneau === "Famille") return 35;
    if (licenceRemark === "Pas besoin de licences") return 35;
    return 120;
};

const fileRequiredValidationSchema = yup
    .mixed()
    .test("fileSize", "Fichier trop gros", (value) => value !== null || (value && value.size <= FILE_SIZE))
    .test("fileFormat", "Type de fichier non supporté", (value) => value !== null || (value && SUPPORTED_FORMATS.includes(value.type)));

const etatcivilSchema = yup
    .object()
    .shape({
        civilite: yup.mixed<Civilite>().oneOf(Object.values(CiviliteNames)).required("civilite obligatoire"),
        firstname: yup.string().required("Prénom obligatoire"),
        lastname: yup.string().required("Nom obligatoire"),
        birthDate: yup.string().matches(frenchDateRegex).required("Date de naissance obligatoire"),
        photo: yup.string(),
        photoBin: yup.mixed().when(["photo"], {
            is: (photo: string) => photo === undefined,
            then: fileRequiredValidationSchema.required("Photo obligatoire"),
            otherwise: yup.mixed().notRequired(),
        }),
    })
    .noUnknown(true);

const contactSchema = yup
    .object()
    .shape({
        addresse: yup.string().required("Addresse obligatoire"),
        zipCode: yup.string().required("Code postal obligatoire"),
        city: yup.string().required("Ville obligatoire"),
        cellPhone: yup.string().required("Numéro de téléhone obligatoire"),
        email: yup.string().email().required("Email obligatoire"),
        receiveSMS: yup.boolean(),
        whatsapp: yup.boolean(),
        receiveEmails: yup.boolean(),
    })
    .noUnknown(true);

const escaladeSchema = yup
    .object()
    .shape({
        climbLevel: yup.mixed<ClimbLevel>().oneOf(Object.values(ClimbLevelNames)).required("le niveau de grimpe est obligatoire"),
        creneau: yup.mixed<Creneau>().oneOf(Object.values(CreneauNames)).required("le créneau est obligatoire"),
        licenceRemark: yup.mixed<LicenceRemark>().oneOf(Object.values(LicenceRemarkNames)).required("LicenceRemark obligatoire"),
        tarifType: yup.mixed<TarifType>().oneOf(Object.values(TarifTypeNames)).required("tarifType obligatoire"),
        tarif: yup
            .number()
            .required("tarif obligatoire")
            .test("minTarif", "Le tarif est trop bas", (value, context) => {
                if (!value) return false;
                return value >= getTarifMin(context.parent.tarifType, context.parent.licenceRemark, context.parent.creneau);
            }),
    })
    .noUnknown(true);

const metaSchema = yup
    .object()
    .shape({
        mode: yup.mixed<InscriptionMode>().oneOf(Object.values(InscriptionModeNames)),
        accepted: yup.boolean().oneOf([true], "Vous devez accepter les conditions d'inscription"),
        id: yup.string().when(["mode"], {
            is: (mode: InscriptionMode) => mode === "reinscription",
            then: yup.string().required("Le id est obligatoire"),
        }),
        sex: yup.mixed<Sex>().oneOf(Object.values(SexNames)),
        fsgtDemandDate: yup.string(),
        fsgtNumber: yup.string(),
        inscriptionDate: yup.string(),
        dateFirstInscription: yup.string(),
        ffmeNumber: yup.string(),
        ffmeDemandDate: yup.string(),
        scancertifObsolete: yup.string(),
        invitationCode: yup.string().when(["mode"], {
            is: (mode: InscriptionMode) => mode === "invitation",
            then: yup.string().required("Le invitationCode est obligatoire"),
        }),
        questionnaireSante: yup.boolean().oneOf([true], "Vous devez avoir répondu non à toutes les questions du forumlaire medicale"),
    })
    .noUnknown(true);

export const memberSchema = yup
    .object()
    .shape({
        //certificat: fullCertificatSchema.required("certificat required"), //certificatSchema.required("certificat required"),
        etatCivil: etatcivilSchema.required("etatcivil required"),
        contact: contactSchema.required("contact required"),
        escalade: escaladeSchema.required("escalade required"),
        meta: metaSchema.required("meta data"),
    })
    .noUnknown(true);

export const searchParamSchema = yup
    .object()
    .shape({
        firstname: yup.string().required("Prénom obligatoire"),
        lastname: yup.string().required("Nom obligatoire"),
        birthDate: yup.string().required("Date de naissance obligatoire"),
    })
    .noUnknown(true);

export const toMember = async (SearchResult: FindMeOutput): Promise<Member> => {
    const result: Member = {
        etatCivil: {
            civilite: SearchResult.member.civilite,
            firstname: SearchResult.member.firstname,
            lastname: SearchResult.member.lastname,
            birthDate: SearchResult.member.birthDate, // todo : string ou date ?   incohérence ave cle yup
            photo: SearchResult.member.photo,
            photoBin: undefined,
        },

        // certificat: {
        //     certificatDate: SearchResult.member.certificatDate,
        //     doctor: SearchResult.member.doctor,
        //     scanCertif: SearchResult.member.scanCertif,
        //     scanCertifBin: undefined,
        // },

        contact: {
            addresse: SearchResult.member.addresse,
            zipCode: SearchResult.member.zipCode,
            city: SearchResult.member.city,
            cellPhone: SearchResult.member.cellPhone,
            email: SearchResult.member.email,
            receiveSMS: SearchResult.member.receiveSMS === 1,
            whatsapp: SearchResult.member.whatsapp === 1,
            receiveEmails: SearchResult.member.receiveEmails === 1,
        },

        escalade: {
            climbLevel: SearchResult.member.climbLevel,
            creneau: SearchResult.member.creneau,
            licenceRemark: SearchResult.member.licenceRemark as LicenceRemark,
            tarifType: SearchResult.member.tarifType,
            tarif: SearchResult.member.tarif,
        },

        meta: {
            accepted: false, // Should accept each time !
            questionnaireSante: false, // Should accept each time !
            id: SearchResult.member.id,
            sex: SearchResult.member.sex,

            fsgtDemandDate: SearchResult.member.fsgtDemandDate,
            fsgtNumber: SearchResult.member.fsgtNumber,
            inscriptionDate: SearchResult.member.inscriptionDate,
            dateFirstInscription: SearchResult.member.dateFirstInscription,
            ffmeNumber: SearchResult.member.ffmeNumber,
            ffmeDemandDate: SearchResult.member.ffmeDemandDate,
            mode: "reinscription",
            //scancertifObsolete: validateCertifDate(SearchResult.member.certificatDate, "reinscription"),
        },
    };

    // transform image :
    if (result.etatCivil.photo) {
        result.etatCivil.photo = convertGoogleLink(result.etatCivil.photo);
    }

    // if (result.certificat.scanCertif) {
    //     result.certificat.scanCertif = convertGoogleLink1(result.certificat.scanCertif);
    // }

    return result;
};

// on a ces deux types d'url,
// https://docs.google.com/file/d/1eyTDbwucbvxA-qUWPuX2PE4L6KLvUhPO/preview?usp=drivesdk
// https://drive.google.com/uc?id=1KnaLn7pPy4w-nyeEBzUxzccWSSQrrIY7
// On veut obtenir celle ci :
// https://drive.google.com/thumbnail?id=/1iF07-OJZ1CI34PJe3T0b1kvIPzmJ-Q37

/**
 * convert  "https://docs.google.com/file/d/1tpb8_YNuRMI5GCC3r1wf6gawgdXyUH5w/preview?usp=drivesdk",
 * to "https://drive.google.com/uc?id=1tpb8_YNuRMI5GCC3r1wf6gawgdXyUH5w",
 * @param input
 * @returns
 */
export const convertGoogleLink = (input: string): string => {
    if (input.startsWith("https://drive.google.com/thumbnail?id=")) {
        return input;
    }
    let id = input;
    if (input.startsWith("https://docs.google.com/file/d/")) {
        id = input.replace("https://docs.google.com/file/d/", "").replace("/preview?usp=drivesdk", "");
    }

    if (input.startsWith("https://drive.google.com/uc?id=")) {
        id = input.replace("https://drive.google.com/uc?id=", "");
    }
    // console.log("convertGoogleLink = ");
    // console.log(" =>  " + input);
    const output = "https://drive.google.com/thumbnail?id=" + id;
    console.log(" =>       " + output);
    console.log(" awaited  https://drive.google.com/thumbnail?id=1iF07-OJZ1CI34PJe3T0b1kvIPzmJ-Q37");
    return output;
};
