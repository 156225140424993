import React, { useState } from "react";
import { Formik, Form } from "formik";
import { getSeason } from "../toolServices/helper";
import { useNavigate } from "react-router-dom";
import { Creds, credsSchema } from "../entities/login";
import LoginComponent from "./inscription/components/Login";
import * as gestApi from "../services/preregister";
import * as ROUTE from "../routing/routesDef";
import "../assets/style/inscription.scss";
import WaitSpinner from "../components/WaitSpinner/WaitSpinner";

const LoginPage: React.FC = () => {
    const [wait, setWait] = useState<boolean>(false);
    const navigate = useNavigate();
    const initialValues: Creds = { email: "", password: "" };

    /**
     * Fonction pour lancer la connexion
     * @param values
     */
    const onSubmit = async (creds: Creds) => {
        setWait(true);

        try {
            await gestApi.postLogin(creds);
            setWait(false);
            navigate(ROUTE.INVITATIONS, { state: creds });
        } catch (e) {
            setWait(false);
        }
    };

    return (
        <div>
            <h1>Connexion au SI du GEST - saison {getSeason()}</h1>

            <Formik initialValues={initialValues} validateOnMount={true} validationSchema={credsSchema} onSubmit={onSubmit} validateOnChange={true}>
                <Form>
                    <LoginComponent />
                    <input type="submit" title="Envoyer" className={"btn btn-submit "} disabled={false} value="Login" />
                </Form>
            </Formik>

            <WaitSpinner wait={wait} />
        </div>
    );
};
export default LoginPage;
