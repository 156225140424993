import Select, { GroupBase, SingleValue, StylesConfig } from "react-select";
import { Labelled, renderSelected } from "../../toolServices/helper";
import "../../assets/style/inscription.scss";

type SelectGroupParams<T> = {
    id: string;
    label: string;
    options: Array<Labelled<T>>;
    value?: T;
    onChange?: any;
    placeholder: string;
    errors?: string;
};

const SelectGroup = <T extends {}>(params: SelectGroupParams<T>): JSX.Element => {
    const customErrorStyles: StylesConfig<Labelled<T>, false, GroupBase<Labelled<T>>> = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            color: "#434D5D",
            border: "1px solid red",

            "&.invalid": {
                color: "#red",
            },
        }),
    };
    return (
        <div className="row">
            <div className={"col-25"}>
                <label className="formlabel" htmlFor={params.id}>
                    {params.label}
                </label>
            </div>

            <div className={"col-75" + (params.errors ? " invalid" : "")}>
                <Select
                    styles={params.errors ? customErrorStyles : undefined}
                    options={params.options}
                    value={params.value ? renderSelected(params.options, params.value) : undefined}
                    onChange={(e: SingleValue<Labelled<T>>) => {
                        if (e && params.onChange) params.onChange(e.value);
                    }}
                    isSearchable={false}
                    isMulti={false}
                    placeholder={params.placeholder}
                    inputId={params.id}
                />
                {params.errors && <span className="error-field">{params.errors as string}</span>}
            </div>
        </div>
    );
};

export default SelectGroup;
