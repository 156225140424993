declare global {
    interface Window {
        _env_: {
            NODE_ENV: 'development' | 'production' | 'test';
            REACT_APP_RECAPTCHA_SITE_KEY: string
            REACT_APP_GEST_API: string
        };
    }
}

window._env_ = Object.assign(process.env || {}, window._env_);

console.log("process.env = ", process.env);
console.log("window._env_ = ", window._env_);
console.log("window._env_.REACT_APP_RECAPTCHA_SITE_KEY = ", window._env_.REACT_APP_RECAPTCHA_SITE_KEY);

export {};
